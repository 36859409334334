import { atom, useAtom } from "jotai";
import { Checkbox, Input } from "antd";
import {atomWithDefault} from "jotai/utils";

export const rnstTabAppendix =
  "* RNST, repetitive nerve stimulation; ADM, abductor digiti minimi; FCU, flexor carpi ulnaris; NA, nasalis; OO, orbicularis oculi; TR, trapezius; QMG, quantitative myasthenia gravis weakness score";

export const qmgTabResult = atom((get) => {
  const qmg = get(QMGAtom);
  return resultQmg(qmg);
});

function resultQmg(qmg) {
  var elem = [];
  if (Object.keys(qmg).every((item) => qmg[item] === "")) {
    return "";
  }

  const sum = Object.keys(qmg)
    .map((item) => {
      return qmg[item] !== "" ? parseInt(qmg[item]) : 0;
    })
    .reduce((acc, item) => {
      return acc + item;
    }, 0);

  elem.push(`QMG : ${sum}점`);
  for (const item of Object.keys(qmg)) {
    if (qmg[item] !== "") {
      elem.push(`- ${item} : ${qmg[item]}`);
    }
  }
  return elem.join("\n");
}

export const rnstTabResult = atom((get) => {
  const rnst = get(RNSTAtom);
  return result(rnst);
});

function result(rnst) {
  var elem = [];
  for (const muscle of Object.keys(rnst["Right"])) {
    for (const side of ["Right", "Left"]) {
      if (muscle === "50 Hz") {
        if (parseFloat(rnst[side][muscle].decrement) >= 100.0) {
          elem.push(
            `- Significant incremental response on high-frequency repetitive nerve stimulation on ${side.toLowerCase()} abudctor digiti minimi muscle.`
          );
        }
        continue;
      }
      if (!rnst[side][muscle].normal) {
        if (rnst[side][muscle].decrement !== "") {
          if (parseFloat(rnst[side][muscle].decrement) <= -10.0) {
            elem.push(
              `- Significant decremental responses to repetitive nerve stimulation on ${side.toLowerCase()} ${muscle.toLowerCase()} muscle.`
            );
          } else {
            elem.push(
              `- No significant decremental responses to repetitive nerve stimulation on ${side.toLowerCase()} ${muscle.toLowerCase()} muscle.`
            );
          }
        }
      } else {
        elem.push(
          `- No significant decremental responses to repetitive nerve stimulation on ${side.toLowerCase()} ${muscle.toLowerCase()} muscle.`
        );
      }
    }
  }

  const elem2 = [];
  for (const muscle of Object.keys(rnst["Right"])) {
    for (const side of ["Right", "Left"]) {
      if (rnst[side][muscle].decrement !== "") {
        elem2.push(`- ${muscle} : ${rnst[side][muscle].decrement}%`);
      }
    }
  }

  if (elem.length === 0 && elem2.length === 0) {
    return "";
  }
  if (elem.length > 0 && elem2.length > 0) {
    return elem.join("\n") + "\n\n" + elem2.join("\n");
  } else if (elem.length > 0) {
    return elem.join("\n");
  } else {
    return elem2.join("\n");
  }
}

export const RNSTAtom = atomWithDefault(()=>({
  Right: {
    "Abductor digiti minimi": {
      normal: false,
      decrement: "",
    },
    "Flexor carpi ulnaris": {
      normal: false,
      decrement: "",
    },
    "Orbicularis oculi": {
      normal: false,
      decrement: "",
    },
    Nasalis: {
      normal: false,
      decrement: "",
    },
    Trapezius: {
      normal: false,
      decrement: "",
    },
    "50 Hz": {
      normal: false,
      decrement: "",
    },
  },
  Left: {
    "Abductor digiti minimi": {
      normal: false,
      decrement: "",
    },
    "Flexor carpi ulnaris": {
      normal: false,
      decrement: "",
    },
    "Orbicularis oculi": {
      normal: false,
      decrement: "",
    },
    Nasalis: {
      normal: false,
      decrement: "",
    },
    Trapezius: {
      normal: false,
      decrement: "",
    },
    "50 Hz": {
      normal: false,
      decrement: "",
    },
  },
}));

const RNSTTable = ({ side }) => {
  const [rnst, setRNST] = useAtom(RNSTAtom);
  return (
    <table className="table-auto">
      <thead className="bg-slate-400">
        <tr>
          <th className="py-1 border-2 text-xs font-medium tracking-wider">정상</th>
          <th className="py-1 border-2 text-xs font-medium tracking-wider">Muscle</th>
          <th className="py-1 border-2 text-xs font-medium tracking-wider">Decrement</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(rnst[side]).map((muscle, index) => (
          <tr key={index} className="items-center text-center">
            <td className="border-2">
              {muscle !== "50 Hz" && (
                <Checkbox
                  checked={rnst[side][muscle].normal}
                  onChange={(e) => {
                    setRNST((prev) => {
                      var newRNST = { ...prev };
                      newRNST[side][muscle]["normal"] = e.target.checked;
                      return newRNST;
                    });
                  }}
                />
              )}
            </td>
            <td className="border-2 text-xs font-medium w-40">{muscle}</td>
            {rnst[side][muscle].normal && <td className="border-2 bg-gray-200" />}
            {!rnst[side][muscle].normal && (
              <td className="border-2">
                <Input
                  size="small"
                  variant="borderless"
                  value={rnst[side][muscle].decrement}
                  onChange={(e) => {
                    setRNST((prev) => {
                      var newRNST = { ...prev };
                      newRNST[side][muscle]["decrement"] = e.target.value;
                      return newRNST;
                    });
                  }}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const QMGAtom = atomWithDefault(()=>({
  Diplopia: "",
  Ptosis: "",
  "Facial weakness": "",
  Swallowing: "",
  Speech: "",
  "Right arm": "",
  "Left arm": "",
  "Vital capacity": "",
  "Right hand grip": "",
  "Left hand grip": "",
  "Head lift": "",
  "Right leg": "",
  "Left leg": "",
}));

const QMGTable = () => {
  const [qmg, setQMG] = useAtom(QMGAtom);
  return (
    <table className="table-auto">
      <thead className="bg-slate-400">
        <tr>
          <th className="px-1 py-1 border-2 text-xs font-medium tracking-wider">항목</th>
          <th className="px-1 py-1 border-2 text-xs font-medium tracking-wider">점수</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(qmg).map((item, index) => (
          <tr key={index} className="items-center text-center">
            <td className="border-2 text-xs font-medium w-40">{item}</td>
            <td className="border-2">
              <Input
                size="small"
                variant="borderless"
                value={qmg[item]}
                onChange={(e) => {
                  setQMG((prev) => {
                    var newQMG = { ...prev };
                    newQMG[item] = e.target.value;
                    return newQMG;
                  });
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const RNSTTab = () => {
  return (
    <div className="flex-1 px-1 py-1">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <h1>Right RNST</h1>
          <RNSTTable side="Right" />
          <div className="h-8" />
          <h1>QMG</h1>
          <QMGTable />
        </div>
        <div className="w-8" />
        <div>
          <h1>Left RNST</h1>
          <RNSTTable side="Left" />
        </div>
      </div>
    </div>
  );
};
