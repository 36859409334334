import React from "react";
import "./App.css";
import {emgUpperAtom, EMGUpperTab, emgUpperTabResult} from "./EMGUpperTab";
import {Button, ConfigProvider, Menu, message} from "antd";
import {atom, useAtomValue} from "jotai";
import {ncsAtom, ncsEmgTabAppendix, NCSTab, ncsTabResult} from "./NCSTab";
import TextArea from "antd/es/input/TextArea";
import {emgLowerAtom, EMGLowerTab, emgLowerTabResult, etcMuscle} from "./EMGLowerTab";
import {QMGAtom, qmgTabResult, RNSTAtom, RNSTTab, rnstTabAppendix, rnstTabResult} from "./RNSTTab";
import {epAtom, epC5Result, epC8Result, epL5Result, epT12Result, EPTab} from "./EPTab";
import {AFTAtom, AFTTab, aftTabResult} from "./AFTTab";
import {ClearOutlined, CopyOutlined} from "@ant-design/icons";
import {useResetAtom} from "jotai/utils";

const items = [
  {
    key: "ncs",
    label: "NCS",
  },
  {
    key: "emg_upper",
    label: "EMG상지",
  },
  {
    key: "emg_lower",
    label: "EMG하지",
  },
  {
    key: "rnst",
    label: "RNST",
  },
  {
    key: "ep",
    label: "EP",
  },
  {
    key: "aft",
    label: "AFT",
  },
];

const summary = atom((get) => {
  var count = 0;
  const elem = [];

  const ncs = get(ncsTabResult);
  if (ncs !== "") {
    count++;
    elem.push(`${count}. 신경전도검사\n${ncs}\n`);
  }

  const emgUpper = get(emgUpperTabResult);
  const emgLower = get(emgLowerTabResult);
  if (emgUpper !== "" || emgLower !== "") {
    count++;
    elem
      .push(`${count}. 근전도검사\n${[emgUpper, emgLower]
        .filter(item => item !== "")
        .join('\n')}\n`);
  }

  if (ncs !== "" || emgUpper !== "" || emgLower !== "") {
    elem.push(`${ncsEmgTabAppendix}\n`);
  }

  const rnst = get(rnstTabResult);
  if (rnst !== "") {
    count++;
    elem.push(`${count}. 반복신경자극검사\n${rnst}\n`);
  }

  const qmg = get(qmgTabResult);
  if (qmg !== "") {
    count++;
    elem.push(`${count}. ${qmg}\n`);
  }

  if (rnst !== "" || qmg !== "") {
    elem.push(`${rnstTabAppendix}\n`);
  }

  const epC5 = get(epC5Result)
  if (epC5 !== "") {
    count++;
    elem.push(`${count}. ${epC5}\n`);
  }

  const epT12 = get(epT12Result)
  if (epT12 !== "") {
    count++;
    elem.push(`${count}. ${epT12}\n`);
  }

  const epC8 = get(epC8Result)
  if (epC8 !== "") {
    count++;
    elem.push(`${count}. ${epC8}\n`);
  }

  const epL5 = get(epL5Result)
  if (epL5 !== "") {
    count++;
    elem.push(`${count}. ${epL5}\n`);
  }

  const aft = get(aftTabResult);
  if (aft !== "") {
    elem.push(`${aft}\n`);
  }

  return elem.join("\n");
});

const App = () => {
  const [tab, setTab] = React.useState("ncs");
  const result = useAtomValue(summary);
  const resetNcs = useResetAtom(ncsAtom);
  const resetAft = useResetAtom(AFTAtom);
  const resetEmgLowerMuscle = useResetAtom(etcMuscle);
  const resetEmgLower = useResetAtom(emgLowerAtom);
  const resetEmgUpper = useResetAtom(emgUpperAtom);
  const resetEp = useResetAtom(epAtom);
  const resetRnst = useResetAtom(RNSTAtom);
  const resetQmg = useResetAtom(QMGAtom);

  const resetData = () => {
    resetNcs();
    resetAft();
    resetEmgLowerMuscle();
    resetEmgLower();
    resetEmgUpper();
    resetEp();
    resetRnst();
    resetQmg();
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          paddingXS: 2
        },
        components: {
          Radio: {
            wrapperMarginInlineEnd: 4,
          },
        },
      }}
    >
      <div className="flex flex-row">
        <Menu
          mode="vertical"
          style={{width: 96}}
          onClick={(e) => setTab(e.key)}
          items={items}
          selectedKeys={[tab]}
        />
        {tab === "ncs" && <NCSTab/>}
        {tab === "emg_upper" && <EMGUpperTab/>}
        {tab === "emg_lower" && <EMGLowerTab/>}
        {tab === "rnst" && <RNSTTab/>}
        {tab === "ep" && <EPTab/>}
        {tab === "aft" && <AFTTab/>}
        <div className="px-8 py-8 w-2/5 flex-1">
          <div className="flex flex-col">
            <div className="h-2"/>
            <div className="flex flex-row">
              <Button shape="round" icon={<ClearOutlined/>} onClick={resetData}>Reset</Button>
              <div className="w-4"/>
              <Button shape="round" icon={<CopyOutlined/>} onClick={
                () => {
                  navigator.clipboard.writeText(result).then(r =>
                    message.success("Copied to clipboard", 1.0)
                  ).catch(e =>
                    message.error("Failed to copy to clipboard", 1.0)
                  );
                }
              }>Copy</Button>
            </div>
            <div className="h-2"/>
            <TextArea rows={31} size={"small"} value={result}/>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default App;
