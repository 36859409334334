import React from "react";
import {Checkbox} from "antd";
import Title from "antd/es/typography/Title";
import {atom, useAtom} from "jotai";
import {atomWithDefault} from 'jotai/utils'

export const ncsEmgTabAppendix =
  "* CMAP, compound muscle action potential; EMG, electromyography; EP, evoked potentials; MUAP, motor unit action potential; NCS, nerve conduction study; NCV, nerve conduction velocity; SNAP, sensory nerve action potential; P-W, palm-wrist; F-W, finger-wrist; W-E, wrist-elbow; E-Ax, elbow-axilla";

export const ncsTabResult = atom((get) => {
  const ncs = get(ncsAtom);
  return result(ncs);
})

export const ncsAtom = atomWithDefault(() => ({
  Right: {
    MotorUpper: {
      Median: [false, false, false, false, false, false],
      Ulnar: [false, false, false, false, false, false],
      Radial: [false, false, false, false, false, false],
    },
    SensoryUpper: {
      Median: [false, false, false, false, false, false],
      Ulnar: [false, false, false, false, false, false],
      "Superficial radial": [false, false, false, false, false, false],
      "Medial antebrachial": [false, false, false, false, false, false],
      "Lateral antebrachial": [false, false, false, false, false, false],
    },
    MotorLower: {
      Peroneal: [false, false, false, false, false, false],
      Tibial: [false, false, false, false, false, false],
    },
    SensoryLower: {
      "Superficial peroneal": [false, false, false, false, false, false],
      Sural: [false, false, false, false, false, false],
      Saphenous: [false, false, false, false, false, false],
      "Lateral femoral cut.": [false, false, false, false, false, false],
    },
    "f-wave": {
      Ulnar: [false, false, false],
      Tibial: [false, false, false],
    },
  },
  Left: {
    MotorUpper: {
      Median: [false, false, false, false, false, false],
      Ulnar: [false, false, false, false, false, false],
      Radial: [false, false, false, false, false, false],
    },
    SensoryUpper: {
      Median: [false, false, false, false, false, false],
      Ulnar: [false, false, false, false, false, false],
      "Superficial radial": [false, false, false, false, false, false],
      "Medial antebrachial": [false, false, false, false, false, false],
      "Lateral antebrachial": [false, false, false, false, false, false],
    },
    MotorLower: {
      Peroneal: [false, false, false, false, false, false],
      Tibial: [false, false, false, false, false, false],
    },
    SensoryLower: {
      "Superficial peroneal": [false, false, false, false, false, false],
      Sural: [false, false, false, false, false, false],
      Saphenous: [false, false, false, false, false, false],
      "Lateral femoral cut.": [false, false, false, false, false, false],
    },
    "f-wave": {
      Ulnar: [false, false, false],
      Tibial: [false, false, false],
    },
  },
  "h-reflex": [false, false, false, false, false],
}));

const NCSTestRow = ({side, section, label}) => {
  const [ncs, setNcs] = useAtom(ncsAtom);
  const checked = ncs[side][section][label];
  const disabled = checked[0] === true;

  const handleCheckboxChange = (e, idx) => {
    var newNcs = {...ncs};
    newNcs[side][section][label][idx] = e.target.checked;
    if (e.target.checked && idx === 0) {
      newNcs[side][section][label].fill(false, 1);
    }
    setNcs(newNcs);
  };

  return (
    <tr className="items-center text-center border-b border-2">
      <td className="border-2">
        <Checkbox checked={checked[0]} onChange={(e) => handleCheckboxChange(e, 0)}/>
      </td>
      <td className="text-left border-2 px-1 py-1 text-xs font-medium text-gray-900">{label}</td>
      <td className="border-2">
        <Checkbox checked={checked[1]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 1)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[2]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 2)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[3]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 3)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[4]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 4)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[5]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 5)}/>
      </td>
    </tr>
  );
};

const NCSSectionRow = ({label}) => (
  <tr className="bg-slate-200 items-center text-center border-b border-2">
    <td/>
    <td className="text-left px-1 py-1 text-xs font-bold text-gray-900">{label}</td>
    <td/>
    <td/>
    <td/>
    <td/>
    <td/>
  </tr>
);

const NCSTable = ({side}) => (
  <table className="divide-y divide-gray-200 table-fixed">
    <thead className="bg-slate-400">
    <tr>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">정상</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider">NCS</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">No potential</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">TL</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">Low amp</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">Slow NCV</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">CB</th>
    </tr>
    </thead>
    <tbody>
    <NCSSectionRow label="Motor"/>
    <NCSTestRow side={side} section="MotorUpper" label="Median"/>
    <NCSTestRow side={side} section="MotorUpper" label="Ulnar"/>
    <NCSTestRow side={side} section="MotorUpper" label="Radial"/>
    <NCSSectionRow label="Sensory"/>
    <NCSTestRow side={side} section="SensoryUpper" label="Median"/>
    <NCSTestRow side={side} section="SensoryUpper" label="Ulnar"/>
    <NCSTestRow side={side} section="SensoryUpper" label="Superficial radial"/>
    <NCSTestRow side={side} section="SensoryUpper" label="Medial antebrachial"/>
    <NCSTestRow side={side} section="SensoryUpper" label="Lateral antebrachial"/>
    <NCSSectionRow label="Motor"/>
    <NCSTestRow side={side} section="MotorLower" label="Peroneal"/>
    <NCSTestRow side={side} section="MotorLower" label="Tibial"/>
    <NCSSectionRow label="Sensory"/>
    <NCSTestRow side={side} section="SensoryLower" label="Superficial peroneal"/>
    <NCSTestRow side={side} section="SensoryLower" label="Sural"/>
    <NCSTestRow side={side} section="SensoryLower" label="Saphenous"/>
    <NCSTestRow side={side} section="SensoryLower" label="Lateral femoral cut."/>
    </tbody>
  </table>
);

const FWaveTestRow = ({side, label}) => {
  const [ncs, setNcs] = useAtom(ncsAtom);
  const section = "f-wave";
  const checked = ncs[side][section][label];
  const disabled = checked[0] === true;

  const handleCheckboxChange = (e, idx) => {
    var newNcs = {...ncs};
    newNcs[side][section][label][idx] = e.target.checked;
    if (e.target.checked && idx === 0) {
      newNcs[side][section][label].fill(false, 1);
    }
    if (e.target.checked && idx === 1) {
      newNcs[side][section][label][2] = false;
    }
    if (e.target.checked && idx === 2) {
      newNcs[side][section][label][1] = false;
    }
    setNcs(newNcs);
  };

  return (
    <tr className="items-center text-center border-b border-2">
      <td className="border-2">
        <Checkbox checked={checked[0]} onChange={(e) => handleCheckboxChange(e, 0)}/>
      </td>
      <td className="text-left border-2 px-1 py-1 whitespace-nowrap text-xs font-medium text-gray-900">{label}</td>
      <td className="border-2">
        <Checkbox checked={checked[1]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 1)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[2]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 2)}/>
      </td>
    </tr>
  );
};

const FWaveTable = ({side}) => (
  <table className="divide-y divide-gray-200 table-fixed">
    <thead className="bg-slate-400">
    <tr>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">정상</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider">F-wave</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">소실</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">지연</th>
    </tr>
    </thead>
    <tbody>
    <FWaveTestRow side={side} label="Ulnar"/>
    <FWaveTestRow side={side} label="Tibial"/>
    </tbody>
  </table>
);

const HReflexTestRow = () => {
  const [ncs, setNcs] = useAtom(ncsAtom);
  const section = "h-reflex";
  const checked = ncs[section];
  const disabled = checked[0] === true;

  const handleCheckboxChange = (e, idx) => {
    var newNcs = {...ncs};
    newNcs[section][idx] = e.target.checked;
    if (e.target.checked && idx === 0) {
      newNcs[section].fill(false, 1);
    }
    if (e.target.checked && idx === 1) {
      newNcs[section][2] = false;
    }
    if (e.target.checked && idx === 2) {
      newNcs[section][1] = false;
    }
    if (e.target.checked && idx === 3) {
      newNcs[section][4] = false;
    }
    if (e.target.checked && idx === 4) {
      newNcs[section][3] = false;
    }
    setNcs(newNcs);
  };

  return (
    <tr className="items-center text-center border-b border-2">
      <td className="border-2">
        <Checkbox checked={checked[0]} onChange={(e) => handleCheckboxChange(e, 0)}/>
      </td>
      <td className="border-2 px-1 py-1 whitespace-nowrap text-xs font-medium text-gray-900"/>
      <td className="border-2">
        <Checkbox checked={checked[1]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 1)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[2]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 2)}/>
      </td>
      <td className="border-2"/>
      <td className="border-2"/>
      <td className="border-2">
        <Checkbox checked={checked[3]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 3)}/>
      </td>
      <td className="border-2">
        <Checkbox checked={checked[4]} disabled={disabled} onChange={(e) => handleCheckboxChange(e, 4)}/>
      </td>
    </tr>
  );
};

const HReflexTable = () => (
  <table className="divide-y divide-gray-200 table-fixed">
    <thead className="bg-slate-400">
    <tr>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">정상</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider">H-reflex</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">소실</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">지연</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-14"></th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider">H-reflex</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">소실</th>
      <th className="border-2 px-1 py-1 text-xs font-medium tracking-wider w-10 break-all">지연</th>
    </tr>
    </thead>
    <tbody>
    <HReflexTestRow/>
    </tbody>
  </table>
);

export const NCSTab = () => {
  return (
    <div className="px-1 py-1 flex-1">
      <div className="flex flex-col w-fit">
        <div className="flex flex-row w-fit">
          <div className="flex flex-col">
            <Title level={5}>Right</Title>
            <NCSTable side="Right"/>
            <div className="h-1"/>
            <FWaveTable side="Right"/>
          </div>
          <div className="w-4"/>
          <div className="flex flex-col">
            <Title level={5}>Left</Title>
            <NCSTable side="Left"/>
            <div className="h-1"/>
            <FWaveTable side="Left"/>
          </div>
        </div>
        <div className="h-1"/>
        <HReflexTable/>
      </div>
    </div>
  );
};

const ncsResult = (ncs, motor, upper, side, nerve) => {
  const section = (motor === "Motor" ? "Motor" : "Sensory") + (upper === "Upper" ? "Upper" : "Lower");
  var printNerve =
    nerve === "Medial antebrachial"
      ? "median antebrachial cutaneous"
      : nerve === "Lateral antebrachial"
        ? "lateral antebrachial cutaneous"
        : nerve === "Lateral femoral cut."
          ? "lateral femoral cutaneous"
          : nerve.toLowerCase();

  if (ncs[side][section][nerve][0]) {
    return `- Normal ${motor.toLowerCase()} NCS on ${side.toLowerCase()} ${printNerve} nerve.`;
  }

  var elem = [];
  if (ncs[side][section][nerve][1]) {
    elem.push(motor === "Motor" ? "no CMAP" : "no SNAP");
  }
  if (ncs[side][section][nerve][2]) {
    elem.push("prolonged terminal latencies");
  }
  if (ncs[side][section][nerve][3]) {
    elem.push(motor === "Motor" ? "low CMAP" : "low SNAP");
  }
  if (ncs[side][section][nerve][4]) {
    elem.push(motor === "Motor" ? "slow motor NCV" : "slow sensory NCV");
  }
  if (ncs[side][section][nerve][5]) {
    elem.push("conduction block");
  }

  if (elem.length === 1) {
    elem[0] = elem[0].charAt(0).toUpperCase() + elem[0].slice(1);
    return `- ${elem[0]} on ${side.toLowerCase()} ${printNerve} nerve.`;
  }
  if (elem.length > 1) {
    elem[0] = elem[0].charAt(0).toUpperCase() + elem[0].slice(1);
    return `- ${elem.slice(0, -1).join(", ")} and ${elem[elem.length - 1]} on ${side.toLowerCase()} ${printNerve} nerve.`;
  }
  return null;
};

const fwaveResult = (ncs, side, nerve) => {
  if (ncs[side]["f-wave"][nerve][0]) {
    return `- Normal latencies of F-wave on ${side.toLowerCase()} ${nerve.toLowerCase()} nerve.`;
  }

  if (ncs[side]["f-wave"][nerve][1]) {
    return `- F-waves are not demonstrated on ${side.toLowerCase()} ${nerve.toLowerCase()} nerve.`;
  }
  if (ncs[side]["f-wave"][nerve][2]) {
    return `- Prolonged latencies of F-waves on ${side.toLowerCase()} ${nerve.toLowerCase()} nerve.`;
  }
  return null;
};

const hReflexResult = (ncs) => {
  if (ncs["h-reflex"][0]) {
    return "- H-reflexes are demonstrated bilaterally.";
  }
  var elem = [];
  if (ncs["h-reflex"][1]) {
    elem.push("- H-reflexes are not demonstrated on right side.");
  }
  if (ncs["h-reflex"][2]) {
    elem.push("- Prolonged latencies of H-reflexes on right side.");
  }
  if (ncs["h-reflex"][3]) {
    elem.push("- H-reflexes are not demonstrated on left side.");
  }
  if (ncs["h-reflex"][4]) {
    elem.push("- Prolonged latencies of H-reflexes on left side.");
  }
  if (elem.length > 0) {
    return elem.join("\n");
  }
  return null;
};

function result(ncs) {
  var elem = [];
  elem.push(ncsResult(ncs, "Motor", "Upper", "Right", "Median"));
  elem.push(ncsResult(ncs, "Motor", "Upper", "Left", "Median"));
  elem.push(ncsResult(ncs, "Motor", "Upper", "Right", "Ulnar"));
  elem.push(ncsResult(ncs, "Motor", "Upper", "Left", "Ulnar"));
  elem.push(ncsResult(ncs, "Motor", "Upper", "Right", "Radial"));
  elem.push(ncsResult(ncs, "Motor", "Upper", "Left", "Radial"));

  elem.push(ncsResult(ncs, "Sensory", "Upper", "Right", "Median"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Left", "Median"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Right", "Ulnar"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Left", "Ulnar"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Right", "Superficial radial"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Left", "Superficial radial"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Right", "Medial antebrachial"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Left", "Medial antebrachial"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Right", "Lateral antebrachial"));
  elem.push(ncsResult(ncs, "Sensory", "Upper", "Left", "Lateral antebrachial"));

  elem.push(ncsResult(ncs, "Motor", "Lower", "Right", "Peroneal"));
  elem.push(ncsResult(ncs, "Motor", "Lower", "Left", "Peroneal"));
  elem.push(ncsResult(ncs, "Motor", "Lower", "Right", "Tibial"));
  elem.push(ncsResult(ncs, "Motor", "Lower", "Left", "Tibial"));

  elem.push(ncsResult(ncs, "Sensory", "Lower", "Right", "Superficial peroneal"));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Left", "Superficial peroneal"));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Right", "Sural"));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Left", "Sural"));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Right", "Saphenous"));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Left", "Saphenous"));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Right", "Lateral femoral cut."));
  elem.push(ncsResult(ncs, "Sensory", "Lower", "Left", "Lateral femoral cut."));

  elem.push(fwaveResult(ncs, "Right", "Ulnar"));
  elem.push(fwaveResult(ncs, "Left", "Ulnar"));
  elem.push(fwaveResult(ncs, "Right", "Tibial"));
  elem.push(fwaveResult(ncs, "Left", "Tibial"));

  elem.push(hReflexResult(ncs));

  if (elem.filter((v) => v != null).length === 0) {
    return "";
  }

  return elem.filter((v) => v != null).join("\n");
}
