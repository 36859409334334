import { Checkbox, Dropdown } from "antd";
import Title from "antd/es/typography/Title";
import { atom, useAtom } from "jotai";
import {atomWithDefault} from "jotai/utils";

export const emgUpperTabResult = atom((get) => {
  const emg = get(emgUpperAtom);
  return result(emg);
});

function result(emg) {
  var elem = [];

  for (const muscleCode of Object.keys(emg["Right"])) {
    for (const side of ["Right", "Left"]) {
      const muscleName = muscleMap.find((m) => m.name === muscleCode).value;
      if (emg[side][muscleCode].normal) {
        elem.push(`- Normal EMG in ${side.toLowerCase()} ${muscleName} muscle.`);
      } else {
        const subElem1 = [];
        const subElem2 = [];
        const subElem3 = [];
        var count = 0;
        for (const column of Object.keys(dropdownColumns)) {
          if (emg[side][muscleCode][column] !== "") {
            if (count < 6) {
              subElem1.push(dropdownColumns[column][emg[side][muscleCode][column]].toLowerCase());
            } else if (count < 9) {
              subElem2.push(dropdownColumns[column][emg[side][muscleCode][column]].toLowerCase());
            } else if (count < 10) {
              subElem3.push(dropdownColumns[column][emg[side][muscleCode][column]].toLowerCase());
            }
          }
          count = count + 1;
        }
        var res1 = "";
        if (subElem1.length === 1) {
          res1 = subElem1[0];
        } else if (subElem1.length > 1) {
          res1 = subElem1.splice(0, subElem1.length - 1).join(", ") + " and " + subElem1[subElem1.length - 1];
        }
        var res2 = "";
        if (subElem2.length > 0) {
          res2 = subElem2.join(", ") + " MUAPs";
        }
        var res3 = "";
        if (subElem3.length > 0) {
          res3 = subElem3[0];
        }
        var final = "";
        if (res1 !== "" && res2 !== "") {
          final += res1 + " and " + res2;
        } else if (res1 !== "") {
          final += res1;
        } else if (res2 !== "") {
          final += res2;
        }
        if (final !== "" && res3 !== "") {
          final += " with " + res3;
        } else if (res3 !== "") {
          final += res3;
        }

        if (final !== "") {
          final = final.charAt(0).toUpperCase() + final.slice(1);
          elem.push(`- ${final} in ${side.toLowerCase()} ${muscleName} muscle.`);
        }
      }
    }
  }

  if (elem.filter((v) => v != null).length === 0) {
    return "";
  }

  return elem.filter((v) => v != null).join("\n");
}

const muscleMap = [
  { name: "Masseter", value: "masseter" },
  { name: "Tongue", value: "genioglossus" },
  { name: "FDI", value: "first dorsal interossei" },
  { name: "ADM", value: "abductor digiti minimi" },
  { name: "FCU", value: "fllexor carpi ulnaris" },
  { name: "FDP(4,5)", value: "flexor digitorum profundus (4,5th)" },
  { name: "APB", value: "abductor pollicis brevis" },
  { name: "FDP(2,3)", value: "flexor digitorum profundus (2,3rd)" },
  { name: "FCR", value: "flexor carpi radialis" },
  { name: "PT", value: "pronator teres" },
  { name: "FPL", value: "flexor pollicis longus" },
  { name: "EIP", value: "extensor indicis proprius" },
  { name: "EDC", value: "extensor digitorum communis" },
  { name: "BR", value: "brachioradialis" },
  { name: "TR", value: "triceps" },
  { name: "BB", value: "biceps brachii" },
  { name: "Deltoid", value: "deltoid" },
  { name: "C5", value: "C5 paraspinal" },
  { name: "C6", value: "C6 paraspinal" },
  { name: "C7", value: "C7 paraspinal" },
  { name: "C8", value: "C8 paraspinal" },
  { name: "T1", value: "T1 paraspinal" },
  { name: "T10", value: "T10 paraspinal" },
  { name: "T12", value: "T12 paraspinal" },
];

const dropdownColumns = {
  Insertion: {
    Inc: "Increased insertion activities",
    Dec: "Decreased insertion activities",
  },
  Fib: {
    "1+": "Grade 1+ fibrillation potentials",
    "2+": "Grade 2+ fibrillation potentials",
    "3+": "Grade 3+ fibrillation potentials",
    "4+": "Grade 4+ fibrillation potentials",
  },
  PSW: {
    "1+": "Grade 1+ positive sharp waves",
    "2+": "Grade 2+ positive sharp waves",
    "3+": "Grade 3+ positive sharp waves",
    "4+": "Grade 4+ positive sharp waves",
  },
  Fasciculation: {
    "+": "Fasciculation potentials",
  },
  Myotonic: {
    "+": "Myotonic discharges",
  },
  CRD: {
    "+": "Complex repetitive discharges",
  },
  Amp: {
    large: "Large amplitude",
    small: "Small amplitude",
  },
  Duration: {
    Long: "Long duration",
    Short: "Short duration",
  },
  Poly: {
    "+": "Polyphasic",
  },
  Recruitment: {
    Early: "Early recruitment",
    Reduced: "Reduced recruitment",
  },
};

const rowDefault = {
  normal: false,
  Insertion: "",
  Fib: "",
  PSW: "",
  Fasciculation: "",
  Myotonic: "",
  CRD: "",
  Amp: "",
  Duration: "",
  Poly: "",
  Recruitment: "",
};

export const emgUpperAtom = atomWithDefault(()=>({
  Right: {
    Masseter: { ...rowDefault },
    Tongue: { ...rowDefault },
    FDI: { ...rowDefault },
    ADM: { ...rowDefault },
    FCU: { ...rowDefault },
    "FDP(4,5)": { ...rowDefault },
    APB: { ...rowDefault },
    "FDP(2,3)": { ...rowDefault },
    FCR: { ...rowDefault },
    PT: { ...rowDefault },
    FPL: { ...rowDefault },
    EIP: { ...rowDefault },
    EDC: { ...rowDefault },
    BR: { ...rowDefault },
    TR: { ...rowDefault },
    BB: { ...rowDefault },
    Deltoid: { ...rowDefault },
    C5: { ...rowDefault },
    C6: { ...rowDefault },
    C7: { ...rowDefault },
    C8: { ...rowDefault },
    T1: { ...rowDefault },
    T10: { ...rowDefault },
    T12: { ...rowDefault },
  },
  Left: {
    Masseter: { ...rowDefault },
    Tongue: { ...rowDefault },
    FDI: { ...rowDefault },
    ADM: { ...rowDefault },
    FCU: { ...rowDefault },
    "FDP(4,5)": { ...rowDefault },
    APB: { ...rowDefault },
    "FDP(2,3)": { ...rowDefault },
    FCR: { ...rowDefault },
    PT: { ...rowDefault },
    FPL: { ...rowDefault },
    EIP: { ...rowDefault },
    EDC: { ...rowDefault },
    BR: { ...rowDefault },
    TR: { ...rowDefault },
    BB: { ...rowDefault },
    Deltoid: { ...rowDefault },
    C5: { ...rowDefault },
    C6: { ...rowDefault },
    C7: { ...rowDefault },
    C8: { ...rowDefault },
    T1: { ...rowDefault },
    T10: { ...rowDefault },
    T12: { ...rowDefault },
  },
}));

const EMGTable = ({ side }) => {
  const [emg, setEMG] = useAtom(emgUpperAtom);
  return (
    <table className="divide-y divide-gray-200 table-fixed">
      <thead className="bg-slate-400">
        <tr>
          <th className="border-2 text-xs font-medium tracking-wider w-8 break-all">정상</th>
          <th className="border-2 text-xs font-medium tracking-wider">Muscle</th>
          {Object.keys(dropdownColumns).map((column, index) => (
            <th
              key={index}
              className={
                column === "Recruitment"
                  ? "border-2 text-xs font-medium tracking-wider w-14 break-all"
                  : "border-2 text-xs font-medium tracking-wider w-8 break-all"
              }
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {muscleMap.map((row, index) => (
          <tr key={index} className="items-center text-center">
            <td className="border-2">
              <Checkbox
                checked={emg[side][row.name].normal}
                onChange={(e) => {
                  setEMG((prev) => {
                    var newEMG = { ...prev };
                    newEMG[side][row.name] = { ...rowDefault, normal: e.target.checked };
                    return newEMG;
                  });
                }}
              />
            </td>
            <td className="text-left border-2 text-xs font-medium text-gray-900 w-20">{row.name}</td>
            {Object.keys(dropdownColumns).map((column, index) =>
              emg[side][row.name].normal ? (
                <td key={index} className="text-center px-0 py-0 border-2 text-xs font-medium text-gray-900 bg-gray-100"></td>
              ) : (
                <td key={index} className="text-center px-0 py-0 border-2 text-xs font-medium text-gray-900">
                  <Dropdown
                    trigger={["click"]}
                    size="small"
                    mouseEnterDelay={0.07}
                    mouseLeaveDelay={0.07}
                    disabled={emg[side][row.name].normal}
                    menu={{
                      items: Object.entries(dropdownColumns[column]).map(([key]) => ({
                        key: key,
                        label: key,
                      })),
                      onClick: ({ key }) => {
                        setEMG((prev) => {
                          var newEMG = { ...prev };
                          newEMG[side][row.name][column] = key;
                          return newEMG;
                        });
                      },
                    }}
                  >
                    <div
                      className={
                        column === "Recruitment"
                          ? "h-5 w-14 items-center text-center break-all"
                          : "h-5 w-8 items-center text-center break-all"
                      }
                    >
                      {emg[side][row.name][column]}
                    </div>
                  </Dropdown>
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const EMGUpperTab = () => {
  return (
    <div className="px-1 py-1 flex-1">
      <div className="flex flex-col w-fit">
        <div className="flex flex-row w-fit">
          <div className="flex flex-col">
            <Title level={5}>Right</Title>
            <EMGTable side="Right" />
          </div>
          <div className="w-4" />
          <div className="flex flex-col">
            <Title level={5}>Left</Title>
            <EMGTable side="Left" />
          </div>
        </div>
      </div>
    </div>
  );
};
