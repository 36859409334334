import {Checkbox} from "antd";
import {atom, useAtom} from "jotai";
import {atomWithDefault} from "jotai/utils";

const sensoryResult = (ep, title, muscleName) => {
  var elem = [];

  if (ep["Center"][muscleName].Normal) {
    elem.push(`- Normal and symmetric latencies of ${muscleName} and cortical EPs bilaterally.`);
    elem.push(`- Normal inter-peak latencies between ${muscleName} and cortical EPs bilaterally.`);
    return title + '\n' + elem.join("\n");
  }

  for (const side of ["Right", "Left"]) {
    if (ep[side][muscleName].Normal) {
      elem.push(`- Normal and symmetric latencies of ${muscleName} and cortical EPs on ${side.toLowerCase()} side.`);
      elem.push(`- Normal inter-peak latencies between ${muscleName} and cortical EPs on ${side.toLowerCase()} side.`);
    }

    var cortical = "";
    if (ep[side][muscleName].CorticalLoss) {
      cortical = "no potentials of"
    } else if (ep[side][muscleName].CorticalDelay) {
      cortical = "prolonged latencies of"
    }

    var muscle = "";
    if (ep[side][muscleName].MuscleLoss) {
      muscle = "no potentials of"
    } else if (ep[side][muscleName].MuscleDelay) {
      muscle = "prolonged latencies of"
    }

    if (cortical !== "" && muscle !== "") {
      elem.push(`- ${cortical.charAt(0).toUpperCase() + cortical.slice(1)} of cortical EPs and ${muscle} of ${muscleName} EPs on ${side.toLowerCase()} side.`);
    } else if (cortical !== "") {
      elem.push(`- ${cortical.charAt(0).toUpperCase() + cortical.slice(1)} of cortical EPs on ${side.toLowerCase()} side.`);
    } else if (muscle !== "") {
      elem.push(`- ${muscle.charAt(0).toUpperCase() + muscle.slice(1)} of ${muscleName} EPs on ${side.toLowerCase()} side.`);
    }

    if (ep[side][muscleName].InterpeakNormal) {
      elem.push(`- Normal inter-peak latencies between ${muscleName} and cortical EPs on ${side.toLowerCase()} side.`);
    } else if (ep[side][muscleName].InterpeakIncrease) {
      elem.push(`- Prolonged inter-peak latencies between ${muscleName} and cortical EPs on ${side.toLowerCase()} side.`);
    }
  }

  if (elem.length === 0) {
    return "";
  }
  return title + '\n' + elem.join("\n");
}
export const epC5Result = atom((get) => {
  const ep = get(epAtom);
  const title = "정중신경 체성감각유발전위검사";
  const muscleName = "C5";
  return sensoryResult(ep, title, muscleName);
})

export const epT12Result = atom((get) => {
  const ep = get(epAtom);
  const title = "후경골신경 체성감각유발전위검사";
  const muscleName = "T12";
  return sensoryResult(ep, title, muscleName);
})

const motorUpperResult = (ep, title, muscleName) => {
  var elem = [];

  if (ep["Center"][muscleName].Normal) {
    elem.push(`- Normal cortical latencies and peripheral motor conduction times bilaterally.`);
    elem.push(`- Normal central motor conduction times bilaterally.`);
    return title + '\n' + elem.join("\n");
  }

  for (const side of ["Right", "Left"]) {
    if (ep[side][muscleName].Normal) {
      elem.push(`- Normal cortical latencies and peripheral motor conduction times on ${side.toLowerCase()} side.`);
      elem.push(`- Normal central motor conduction times on ${side.toLowerCase()} side.`);
    }

    var cortical = "";
    if (ep[side][muscleName].CorticalLoss) {
      cortical = "no MEPs"
    } else if (ep[side][muscleName].CorticalDelay) {
      cortical = "prolonged latencies of MEPs"
    }

    var muscle = "";
    if (ep[side][muscleName].MuscleLoss) {
      muscle = "no MEPs"
    } else if (ep[side][muscleName].MuscleDelay) {
      muscle = "prolonged latencies of MEPs"
    }

    if (cortical !== "" && muscle !== "") {
      elem.push(`- ${cortical.charAt(0).toUpperCase() + cortical.slice(1)} after cortical stimulation and ${muscle} after cervical stimulation on ${side.toLowerCase()} side.`);
    } else if (cortical !== "") {
      elem.push(`- ${cortical.charAt(0).toUpperCase() + cortical.slice(1)} after cortical stimulation on ${side.toLowerCase()} side.`);
    } else if (muscle !== "") {
      elem.push(`- ${muscle.charAt(0).toUpperCase() + muscle.slice(1)} after cervical stimulation on ${side.toLowerCase()} side.`);
    }

    if (ep[side][muscleName].InterpeakNormal) {
      elem.push(`- Normal central motor conduction times on ${side.toLowerCase()} side.`);
    } else if (ep[side][muscleName].InterpeakIncrease) {
      elem.push(`- Prolonged central motor conduction times on ${side.toLowerCase()} side.`);
    }
  }

  if (elem.length === 0) {
    return "";
  }
  return title + '\n' + elem.join("\n");
}

export const epC8Result = atom((get) => {
  const ep = get(epAtom);
  const title = "상지 운동유발전위검사";
  const muscleName = "C8";
  return motorUpperResult(ep, title, muscleName);
})

const motorLowerResult = (ep, title, muscleName) => {
  var elem = [];

  if (ep["Center"][muscleName].Normal) {
    elem.push(`- Normal cortical latencies and peripheral motor conduction times bilaterally.`);
    elem.push(`- Normal central motor conduction times bilaterally.`);
    return title + '\n' + elem.join("\n");
  }

  for (const side of ["Right", "Left"]) {
    if (ep[side][muscleName].Normal) {
      elem.push(`- Normal cortical latencies and peripheral motor conduction times on ${side.toLowerCase()} side.`);
      elem.push(`- Normal central motor conduction times on ${side.toLowerCase()} side.`);
    }

    var cortical = "";
    if (ep[side][muscleName].CorticalLoss) {
      cortical = "no MEPs"
    } else if (ep[side][muscleName].CorticalDelay) {
      cortical = "prolonged latencies of MEPs"
    }

    var muscle = "";
    if (ep[side][muscleName].MuscleLoss) {
      muscle = "no MEPs"
    } else if (ep[side][muscleName].MuscleDelay) {
      muscle = "prolonged latencies of MEPs"
    }

    if (cortical !== "" && muscle !== "") {
      elem.push(`- ${cortical.charAt(0).toUpperCase() + cortical.slice(1)} after cortical stimulation and ${muscle} after lumber stimulation on ${side.toLowerCase()} side.`);
    } else if (cortical !== "") {
      elem.push(`- ${cortical.charAt(0).toUpperCase() + cortical.slice(1)} after cortical stimulation on ${side.toLowerCase()} side.`);
    } else if (muscle !== "") {
      elem.push(`- ${muscle.charAt(0).toUpperCase() + muscle.slice(1)} after lumber stimulation on ${side.toLowerCase()} side.`);
    }

    if (ep[side][muscleName].InterpeakNormal) {
      elem.push(`- Normal central motor conduction times on ${side.toLowerCase()} side.`);
    } else if (ep[side][muscleName].InterpeakIncrease) {
      elem.push(`- Prolonged central motor conduction times on ${side.toLowerCase()} side.`);
    }
  }

  if (elem.length === 0) {
    return "";
  }
  return title + '\n' + elem.join("\n");
}

export const epL5Result = atom((get) => {
  const ep = get(epAtom);
  const title = "하지 운동유발전위검사";
  const muscleName = "L5";
  return motorLowerResult(ep, title, muscleName);
})

const rowDefault = {
  Normal: false,
  CorticalLoss: false,
  CorticalDelay: false,
  MuscleLoss: false,
  MuscleDelay: false,
  InterpeakNormal: false,
  InterpeakIncrease: false,
};

export const epAtom = atomWithDefault(()=>({
  Right: {
    C5: { ...rowDefault },
    T12: { ...rowDefault },
    C8: { ...rowDefault },
    L5: { ...rowDefault },
  },
  Center: {
    C5: { Normal: false },
    T12: { Normal: false },
    C8: { Normal: false },
    L5: { Normal: false },
  },
  Left: {
    C5: { ...rowDefault },
    T12: { ...rowDefault },
    C8: { ...rowDefault },
    L5: { ...rowDefault },
  },
}));

const EPTable = ({ side, muscle }) => {
  const [ep, setEP] = useAtom(epAtom);
  const handleChange = (side, muscle, col, e) => {
    setEP((prev) => {
      var newEP = { ...prev };
      if (col === "Normal") {
        newEP[side][muscle] = { ...rowDefault, Normal: e.target.checked };
        return newEP;
      } else if (col === "CorticalLoss") {
        newEP[side][muscle].CorticalLoss = e.target.checked;
        newEP[side][muscle].CorticalDelay = e.target.checked === true ? false : newEP[side][muscle].CorticalDelay;
      } else if (col === "CorticalDelay") {
        newEP[side][muscle].CorticalDelay = e.target.checked;
        newEP[side][muscle].CorticalLoss = e.target.checked === true ? false : newEP[side][muscle].CorticalLoss;
      } else if (col === "MuscleLoss") {
        newEP[side][muscle].MuscleLoss = e.target.checked;
        newEP[side][muscle].MuscleDelay = e.target.checked === true ? false : newEP[side][muscle].MuscleDelay;
      } else if (col === "MuscleDelay") {
        newEP[side][muscle].MuscleDelay = e.target.checked;
        newEP[side][muscle].MuscleLoss = e.target.checked === true ? false : newEP[side][muscle].MuscleLoss;
      } else if (col === "InterpeakNormal") {
        newEP[side][muscle].InterpeakNormal = e.target.checked;
        newEP[side][muscle].InterpeakIncrease = e.target.checked === true ? false : newEP[side][muscle].InterpeakIncrease;
      } else if (col === "InterpeakIncrease") {
        newEP[side][muscle].InterpeakIncrease = e.target.checked;
        newEP[side][muscle].InterpeakNormal = e.target.checked === true ? false : newEP[side][muscle].InterpeakNormal;
      }
      return newEP;
    });
  };

  const row = ep[side][muscle];
  const rowCenterNormal = ep["Center"][muscle].Normal;
  const normal = row.Normal || rowCenterNormal

  return (
    <table className="table-auto">
      <thead className="bg-slate-400">
      <tr>
        <th rowSpan="2" className="text-xs font-medium px-1 py-1 border-2">
          정상
        </th>
        <th colSpan="2" className="text-xs font-medium px-1 py-1 border-2">
          Cortical
        </th>
        <th colSpan="2" className="text-xs font-medium px-1 py-1 border-2">
          {muscle}
        </th>
        <th colSpan="2" className="text-xs font-medium px-1 py-1 border-2">
          Interpeak
        </th>
      </tr>
      <tr>
        <th className="text-xs font-medium px-1 py-1 border-2 w-10">소실</th>
        <th className="text-xs font-medium px-1 py-1 border-2 w-10">지연</th>
        <th className="text-xs font-medium px-1 py-1 border-2 w-10">소실</th>
        <th className="text-xs font-medium px-1 py-1 border-2 w-10">지연</th>
        <th className="text-xs font-medium px-1 py-1 border-2 w-10">정상</th>
        <th className="text-xs font-medium px-1 py-1 border-2 w-10">증가</th>
      </tr>
      </thead>
      <tbody>
      <tr className="text-center">
        <td className="border-2">
          <Checkbox checked={row.Normal} disabled={rowCenterNormal} onChange={(e) => handleChange(side, muscle, "Normal", e)} />
        </td>
        <td className="border-2">
          <Checkbox checked={row.CorticalLoss} disabled={normal || row.CorticalDelay} onChange={(e) => handleChange(side, muscle, "CorticalLoss", e)} />
        </td>
        <td className="border-2">
          <Checkbox checked={row.CorticalDelay} disabled={normal || row.CorticalLoss} onChange={(e) => handleChange(side, muscle, "CorticalDelay", e)} />
        </td>
        <td className="border-2">
          <Checkbox checked={row.MuscleLoss} disabled={normal || row.MuscleDelay} onChange={(e) => handleChange(side, muscle, "MuscleLoss", e)} />
        </td>
        <td className="border-2">
          <Checkbox checked={row.MuscleDelay} disabled={normal || row.MuscleLoss} onChange={(e) => handleChange(side, muscle, "MuscleDelay", e)} />
        </td>
        <td className="border-2">
          <Checkbox checked={row.InterpeakNormal} disabled={normal || row.InterpeakIncrease} onChange={(e) => handleChange(side, muscle, "InterpeakNormal", e)} />
        </td>
        <td className="border-2">
          <Checkbox checked={row.InterpeakIncrease} disabled={normal || row.InterpeakNormal} onChange={(e) => handleChange(side, muscle, "InterpeakIncrease", e)} />
        </td>
      </tr>
      </tbody>
    </table>
  );
};

const EPCenterTable = ({ muscle }) => {
  const [ep, setEP] = useAtom(epAtom);
  const handleChange = (muscle, e) => {
    setEP((prev) => {
      var newEP = { ...prev };
      newEP["Center"][muscle]["Normal"] = e.target.checked;
      if (e.target.checked === true) {
        newEP['Right'][muscle] = { ...rowDefault };
        newEP['Left'][muscle] = { ...rowDefault };
      }
      return newEP;
    });
  };
  return (
    <table className="table-auto">
      <thead className="bg-slate-400">
      <tr>
        <th className="text-xs font-medium px-1 py-1 border-2 w-16">양쪽 정상</th>
      </tr>
      </thead>
      <tbody>
      <tr className="text-center">
        <td className="border-2 h-12">
          <Checkbox checked={ep["Center"][muscle].Normal} onChange={(e) => handleChange(muscle, e)} />
        </td>
      </tr>
      </tbody>
    </table>
  );
};

export const EPTab = () => {
  return (
    <div className="flex-1 px-1 py-1">
      <div className="flex flex-row">
        <h1>Right</h1>
        <div className="w-80" />
        <h1>Left</h1>
      </div>
      <div className="h-2" />
      <div className="flex flex-row">
        <h1>SEP (상지)</h1>
      </div>
      <div className="flex flex-row items-stretch">
        <EPTable side="Right" muscle="C5" />
        <div className="w-4" />
        <EPCenterTable muscle="C5" />
        <div className="w-4" />
        <EPTable side="Left" muscle="C5" />
      </div>
      <div className="h-2" />
      <div className="flex flex-row">
        <h1>SEP (하지)</h1>
      </div>
      <div className="flex flex-row items-stretch">
        <EPTable side="Right" muscle="T12" />
        <div className="w-4" />
        <EPCenterTable muscle="T12" />
        <div className="w-4" />
        <EPTable side="Left" muscle="T12" />
      </div>
      <div className="h-2" />
      <div className="flex flex-row">
        <h1>MEP (상지)</h1>
      </div>
      <div className="flex flex-row items-stretch">
        <EPTable side="Right" muscle="C8" />
        <div className="w-4" />
        <EPCenterTable muscle="C8" />
        <div className="w-4" />
        <EPTable side="Left" muscle="C8" />
      </div>
      <div className="h-2" />
      <div className="flex flex-row">
        <h1>MEP (하지)</h1>
      </div>
      <div className="flex flex-row items-stretch">
        <EPTable side="Right" muscle="L5" />
        <div className="w-4" />
        <EPCenterTable muscle="L5" />
        <div className="w-4" />
        <EPTable side="Left" muscle="L5" />
      </div>
    </div>
  );
};
