import {Input, Radio} from "antd";
import {atom, useAtom, useAtomValue} from "jotai";
import {atomWithDefault} from "jotai/utils";

export const aftTabResult = atom((get) => {
  var elem = [];
  const aft = get(AFTAtom);

  if (Object.keys(aft).every((item) => aft[item] === "" || item === "Baseline BP" || item === "Minimum BP")) {
    if (aft["Baseline BP"]["SBP"] === "" && aft["Baseline BP"]["PR"] === "" && aft["Minimum BP"]["SBP"] === "" && aft["Minimum BP"]["PR"] === "") {
      return "";
    }
  }

  elem.push("------------------\n")

  /*
  1. 심호흡심박동검사 (Heart rate response to deep breathing)
  Normal ( ), abnormal ( ), borderline ( )
  Average E-I difference : ( 값 ) (normal / abnormal)
  E-I ratio (최대 HR/최소 HR) : ( 값 ) (normal / abnormal)
   */
  const eiDifference = get(EIDifference);
  const eiRatio = get(EIRatio);
  if (eiDifference !== "" && eiRatio !== "") {
    elem.push("1. 심호흡심박동검사 (Heart rate response to deep breathing)");
    const t1Normal = eiDifference === "정상" && eiRatio === "정상";
    elem.push(`Normal (${t1Normal ? 'O' : ' '}), abnormal (${t1Normal ? ' ' : 'O'}), borderline ( )`);
    elem.push(`Average E-I difference : ${aft["E-I difference"]} (${eiDifference === '정상' ? 'normal' : 'abnormal'})`);
    elem.push(`E-I ratio (최대 HR/최소 HR) : ${aft["E-I ratio"]} (${eiRatio === '정상' ? 'normal' : 'abnormal'})`);
    elem.push('');
  }
  /*
  2. 발살바 수기 (Valsalva maneuver)
  Valsalva ratio : ( 값 ) (abnormal)
  Late phase II : normal ( ), abnormal (O ), borderline ( )
  Phase IV : normal ( ), abnormal ( ), borderline (O )
  */
  const valsalvaRatio = get(ValsalvaRatio);
  if (valsalvaRatio !== "") {
    elem.push("2. 발살바 수기 (Valsalva maneuver)");
    elem.push(`Valsalva ratio : ${aft["Valsalva ratio"]} (${valsalvaRatio === '정상' ? 'normal' : 'abnormal'})`);
    elem.push(`Late phase II : normal (${aft["Last phase II"] === '정상' ? 'O' : ' '}), abnormal (${aft["Last phase II"] === '정상' ? ' ' : 'O'}), borderline ( )`);
    elem.push(`Phase IV : normal (${aft["Phase IV"] === '정상' ? 'O' : ' '}), abnormal (${aft["Phase IV"] === '정상' ? ' ' : 'O'}), borderline ( )`);
    elem.push('');
  }

  /*
  3. 기립경사검사 (head up tilt table test)
  Normal ( )
  hypotension : orthostatic ( ), initial orthostatic ( ), delayed orthostatic ( )
  Postural tachycardia syndrome ( )
  Syncope ( )
  dHR/dsBP ratio ( 값 ) (neurogenic / borderline / non-neurogenic)
  */
  const tiltTest = aft["Tilt test"];
  const dhr = get(dHR);
  const neurogenic = get(Neurogenic).toLowerCase();
  if (tiltTest !== "") {
    elem.push("3. 기립경사검사 (head up tilt table test)");
    elem.push(`Normal (${tiltTest === 'Normal' ? 'O' : ' '})`);
    elem.push(`hypotension : orthostatic (${tiltTest === 'Orthostatic hypotension' ? 'O' : ' '}), initial orthostatic (${tiltTest === 'Initial orthostatic hypotension' ? 'O' : ' '}), delayed orthostatic ( )`);
    elem.push(`Postural tachycardia syndrome (${tiltTest === 'POTS' ? 'O' : ' '})`);
    elem.push(`Syncope ( )`);
    elem.push(`dHR/dsBP ratio : ${dhr} (${neurogenic})`);
    elem.push('');
  }

  /*
  4. Tilt test BP data
  */
  elem.push("4. Tilt test BP data\n");

  /*
  5. 정량적땀분비축삭반사검사 (Quantitative Sudomotor Axon Reflex Test)
  Normal ( )
  Hypohydrosis ( ) : distal arm ( ), proximal leg ( ), distal leg ( ), foot ( )
  Hyperhydrosis ( ) : distal arm ( ), proximal leg ( ), distal leg ( ), foot ( )
  */
  const distalArm = get(DistalArm);
  const proximalLeg = get(ProximalLeg);
  const distalLeg = get(DistalLeg);
  const foot = get(Foot);
  if (distalArm !== "" && proximalLeg !== "" && distalLeg !== "" && foot !== "") {
    const distalArmAbnormal = distalArm === '감소' || distalArm === '많이감소';
    const proximalLegAbnormal = proximalLeg === '감소' || proximalLeg === '많이감소';
    const distalLegAbnormal = distalLeg === '감소' || distalLeg === '많이감소';
    const footAbnormal = foot === '감소' || foot === '많이감소';
    elem.push("5. 정량적땀분비축삭반사검사 (Quantitative Sudomotor Axon Reflex Test)");
    elem.push(`Normal (${distalArm === '정상' && proximalLeg === '정상' && distalLeg === '정상' && foot === '정상' ? 'O' : ' '})`);
    elem.push(`Hypohydrosis ( ) : distal arm (${distalArmAbnormal ? 'O' : ' '}), proximal leg (${proximalLegAbnormal ? 'O' : ' '}), distal leg (${distalLegAbnormal ? 'O' : ' '}), foot (${footAbnormal ? 'O' : ' '})`);
    elem.push(`Hyperhydrosis ( ) : distal arm ( ), proximal leg ( ), distal leg ( ), foot ( )`);
    elem.push('');
  }

  /*
  6. CASS score
  ① Sudomotor subscore : ( ) 점
  ② Cardiovagal subscore : ( ) 점
  ③ Adrenergic subscore : ( ) 점
  - Total : ( 값 )
  */
  /*
  ①②③④가 ( ) 모두 정상이면 🡪 0
  ①②③④ 중에 감소가 ( 1개이면 🡪 1 )
  ①②③④ ratio 중에 많이감소가 1개 있고 나머지는 정상이면 🡪 2
  ①②③④ 중에 감소가 2개 이상, 나머지가 정상이면 🡪 2
  ①②③④ 중에 많이감소가 1개이고 감소가 1개 이상이면 🡪 3
  ①②③④ 많이감소가 2개 이상이면 🡪 3

   */
  var sudoMotoSubscore = "";
  if (distalArm !== "" && proximalLeg !== "" && distalLeg !== "" && foot !== "") {
    const countDecrease
      = [distalArm, proximalLeg, distalLeg, foot].filter(value => value === '감소').length;
    const countSevereDecrease
      = [distalArm, proximalLeg, distalLeg, foot].filter(value => value === '많이감소').length;
    if (countDecrease === 0 && countSevereDecrease === 0) {
      sudoMotoSubscore = "0";
    } else if (countDecrease === 1 && countSevereDecrease === 0) {
      sudoMotoSubscore = "1";
    } else if (countDecrease === 0 && countSevereDecrease === 1) {
      sudoMotoSubscore = "2";
    } else if (countDecrease >= 2 && countSevereDecrease === 0) {
      sudoMotoSubscore = "2";
    } else if (countDecrease >= 1 && countSevereDecrease === 1) {
      sudoMotoSubscore = "3";
    } else if (countSevereDecrease >= 2) {
      sudoMotoSubscore = "3";
    }
  }
  const parasympathetic = get(Parasympathetic);
  const sympathetic = get(Sympathetic);
  if (sudoMotoSubscore !== "" && parasympathetic !== "" && sympathetic !== "") {
    const total = parseInt(sudoMotoSubscore) + parseInt(parasympathetic) + parseInt(sympathetic);
    elem.push("6. CASS score");
    elem.push(`① Sudomotor subscore : ${sudoMotoSubscore} 점`);
    elem.push(`② Cardiovagal subscore : ${parasympathetic} 점`);
    elem.push(`③ Adrenergic subscore : ${sympathetic} 점`);
    elem.push(`- Total : ${total}`);
    elem.push('');
  }

  /*
  Heart rate response to deep breathing에서 ( ).
  Valsalva maneuver에서 parasympathetic cholinergic dysfunction을 시사하는 소견이 관찰됨.
  Tilt table test 에서 initial orthostatic hypotension (neurogenic)을 시사하는 소견이 관찰됨.
  Quantitative Sudomotor Axon Reflex Test상 땀분비는 정상임.
  */
  if (eiDifference !== "" && eiRatio !== "") {
    if (eiDifference === "정상" && eiRatio === "정상") {
      elem.push(`Heart rate response to deep breathing에서 이상 소견은 관찰되지 않음.`);
    } else {
      elem.push(`Heart rate response to deep breathing에서 cardiovagal dysfunction을 시사하는 소견이 관찰됨.`);
    }
  }

  if (valsalvaRatio !== "" && aft["Last phase II"] !== "" && aft["Phase IV"] !== "") {
    /*
    ①이 감소이고, ②,③이 정상이면 parasympathetic cholinergic dysfunction을 시사하는 소견이 관찰됨
    ①이 정상이고, ②,③ 중에 하나라도 정상이 아니면 sympathetic adrenergic dysfunction을 시사하는 소견이 관찰됨
    ①이 감소이고, ②,③ 중에 하나라도 정상이 아니면 sympathetic adrenergic and parasympathetic cholinergic dysfunction을 시사하는 소견이 관찰됨
     */
    if (valsalvaRatio === "정상" && aft["Last phase II"] === "정상" && aft["Phase IV"] === "정상") {
      elem.push(`Valsalva maneuver에서 이상 소견은 관찰되지 않음.`);
    } else if (valsalvaRatio === "감소" && aft["Last phase II"] === "정상" && aft["Phase IV"] === "정상") {
      elem.push(`Valsalva maneuver에서 parasympathetic cholinergic dysfunction을 시사하는 소견이 관찰됨.`);
    } else if (valsalvaRatio === "정상" && (aft["Last phase II"] !== "정상" || aft["Phase IV"] !== "정상")) {
      elem.push(`Valsalva maneuver에서 sympathetic adrenergic dysfunction을 시사하는 소견이 관찰됨.`);
    } else if (valsalvaRatio === "감소" && (aft["Last phase II"] !== "정상" || aft["Phase IV"] !== "정상")) {
      elem.push(`Valsalva maneuver에서 sympathetic adrenergic and parasympathetic cholinergic dysfunction을 시사하는 소견이 관찰됨.`);
    }
  }

  /*
  ①이 정상이면 의미 있는 혈압 변화 혹은 심박수 변화는 관찰되지 않음.
  ①이 Orthostatic hypotension이면 orthostatic hypotension을 시사하는 소견이 관찰됨.
  ①이 Initial orthostatic hypotension이면 initial orthostatic hypotension을 시사하는 소견이 관찰됨.
  ①이 POTS이면 postural orthostatic tachycardia syndrome을 시사하는 소견이 관찰됨.
   */
  if (tiltTest !== "") {
    if (tiltTest === "Normal") {
      elem.push(`Tilt table test에서 의미 있는 혈압 변화 혹은 심박수 변화는 관찰되지 않음.`);
    } else if (tiltTest === "Orthostatic hypotension") {
      elem.push(`Tilt table test에서 orthostatic hypotension을 시사하는 소견이 관찰됨.`);
    } else if (tiltTest === "Initial orthostatic hypotension") {
      elem.push(`Tilt table test에서 initial orthostatic hypotension을 시사하는 소견이 관찰됨.`);
    } else if (tiltTest === "POTS") {
      elem.push(`Tilt table test에서 postural orthostatic tachycardia syndrome을 시사하는 소견이 관찰됨.`);
    }
  }
  /*
  ①,②,③,④ 모두 정상이면 땀분비는 정상임
  감소 또는 많이 감소한 부위가 있으면 ( ), ( ), ( )에서 땀분비가 감소함
  예를 들어 distal arm이 감소, foot이 많이 감소한 경우 distal arm, foot에서 땀분비가 감소함.
   */
  if (distalArm !== "" && proximalLeg !== "" && distalLeg !== "" && foot !== "") {
    if (distalArm === "정상" && proximalLeg === "정상" && distalLeg === "정상" && foot === "정상") {
      elem.push(`Quantitative Sudomotor Axon Reflex Test상 땀분비는 정상임.`);
    } else {
      var subelem = [];
      if (distalArm !== "정상") {
        subelem.push("distal arm");
      }
      if (proximalLeg !== "정상") {
        subelem.push("proximal leg");
      }
      if (distalLeg !== "정상") {
        subelem.push("distal leg");
      }
      if (foot !== "정상") {
        subelem.push("foot");
      }
      elem.push(`Quantitative Sudomotor Axon Reflex Test상 ${subelem.join(", ")}에서 땀분비가 감소함.`);
    }
  }
  if (elem.length === 0 || (elem.length === 1 && elem[0] === '4. Tilt test BP data\n')) {
    return "";
  }

  return elem.join("\n");
});

export const AFTAtom = atomWithDefault(() => ({
  나이: "",
  성별: "",
  "E-I difference": "",
  "E-I ratio": "",
  "Valsalva ratio": "",
  "Last phase II": "",
  "Phase IV": "",
  "Tilt test": "",
  "Baseline BP": {
    SBP: "",
    PR: "",
  },
  "Minimum BP": {
    SBP: "",
    PR: "",
  },
  "Distal arm": "",
  "Proximal leg": "",
  "Distal leg": "",
  Foot: "",
}));

const EIDifference = atom((get) => {
  const aft = get(AFTAtom);
  const ageEIdifferences = [
    {minAge: 10, maxAge: 29, normal: 14, decrease: 7},
    {minAge: 30, maxAge: 39, normal: 12, decrease: 6},
    {minAge: 40, maxAge: 49, normal: 10, decrease: 5},
    {minAge: 50, maxAge: 59, normal: 9, decrease: 4.5},
    {minAge: 60, maxAge: Infinity, normal: 7, decrease: 3.5},
  ];

  if (aft["나이"] === "" || aft["E-I difference"] === "") {
    return "";
  }

  const ageRange = ageEIdifferences.find(({minAge, maxAge}) => aft["나이"] >= minAge && aft["나이"] <= maxAge);

  if (ageRange) {
    if (aft["E-I difference"] >= ageRange.normal) {
      return "정상";
    } else if (aft["E-I difference"] > ageRange.decrease) {
      return "감소";
    } else {
      return "많이감소";
    }
  }
  return "";
});

const EIRatio = atom((get) => {
  const aft = get(AFTAtom);
  const ageEIratios = [
    {minAge: 16, maxAge: 20, ratio: 1.23},
    {minAge: 21, maxAge: 25, ratio: 1.20},
    {minAge: 26, maxAge: 30, ratio: 1.18},
    {minAge: 31, maxAge: 35, ratio: 1.16},
    {minAge: 36, maxAge: 40, ratio: 1.14},
    {minAge: 41, maxAge: 45, ratio: 1.12},
    {minAge: 46, maxAge: 50, ratio: 1.11},
    {minAge: 51, maxAge: 55, ratio: 1.09},
    {minAge: 56, maxAge: 60, ratio: 1.08},
    {minAge: 61, maxAge: 65, ratio: 1.07},
    {minAge: 66, maxAge: 70, ratio: 1.06},
    {minAge: 71, maxAge: 75, ratio: 1.06},
    {minAge: 76, maxAge: 80, ratio: 1.05},
  ];
  if (aft["나이"] === "" || aft["E-I ratio"] === "") {
    return ""
  }
  const ageRange = ageEIratios.find(({minAge, maxAge}) => aft["나이"] >= minAge && aft["나이"] <= maxAge);
  if (ageRange) {
    return aft["E-I ratio"] > ageRange.ratio ? "정상" : "감소";
  }
  return "";
})

const ValsalvaRatio = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["나이"] === "" || aft["성별"] === "" || aft["Valsalva ratio"] === "") {
    return "";
  }
  if (aft["나이"] >= 10 && aft["나이"] <= 29) {
    return aft["성별"] === "남" ? (aft["Valsalva ratio"] >= 1.59 ? "정상" : "감소") : (aft["Valsalva ratio"] >= 1.46 ? "정상" : "감소");
  } else if (aft["나이"] >= 30 && aft["나이"] <= 39) {
    return aft["성별"] === "남" ? (aft["Valsalva ratio"] >= 1.52 ? "정상" : "감소") : (aft["Valsalva ratio"] >= 1.50 ? "정상" : "감소");
  } else if (aft["나이"] >= 40 && aft["나이"] <= 49) {
    return aft["성별"] === "남" ? (aft["Valsalva ratio"] >= 1.44 ? "정상" : "감소") : (aft["Valsalva ratio"] >= 1.51 ? "정상" : "감소");
  } else if (aft["나이"] >= 50 && aft["나이"] <= 59) {
    return aft["성별"] === "남" ? (aft["Valsalva ratio"] >= 1.36 ? "정상" : "감소") : (aft["Valsalva ratio"] >= 1.47 ? "정상" : "감소");
  } else if (aft["나이"] >= 60) {
    return aft["성별"] === "남" ? (aft["Valsalva ratio"] >= 1.29 ? "정상" : "감소") : (aft["Valsalva ratio"] >= 1.39 ? "정상" : "감소");
  }
  return "";
})

const Parasympathetic = atom((get) => {
  const eiDifference = get(EIDifference);
  const eiRatio = get(EIRatio);
  const valsalvaRatio = get(ValsalvaRatio);

  if (eiDifference === "" || eiRatio === "" || valsalvaRatio === "") {
    return "";
  }

  if (eiDifference === "많이감소" && valsalvaRatio === "정상") {
    return "2";
  } else if (eiDifference === "많이감소" && valsalvaRatio === "감소") {
    return "3";
  } else if (eiDifference === "정상" && eiRatio === "정상" && valsalvaRatio === "정상") {
    return "0";
  } else {
    return "1";
  }
});

const Sympathetic = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["Last phase II"] === "" || aft["Tilt test"] === "") {
    return "";
  }
  const lp2Score = {
    "정상": 0,
    "mild": 1,
    "mod": 2,
    "severe": 3,
  };
  const tiltScore = {
    "Normal": 0,
    "Orthostatic hypotension": 1,
    "Initial orthostatic hypotension": 1,
    "POTS": 0,
  }
  if (lp2Score[aft["Last phase II"]] === 0 && tiltScore[aft["Tilt test"]] === 0) {
    return "0";
  } else if (lp2Score[aft["Last phase II"]] === 1 && tiltScore[aft["Tilt test"]] === 0) {
    return "1";
  } else if (lp2Score[aft["Last phase II"]] === 2 && tiltScore[aft["Tilt test"]] === 0) {
    return "2";
  } else if (lp2Score[aft["Last phase II"]] === 3 && tiltScore[aft["Tilt test"]] === 1) {
    return "4";
  } else {
    return "3";
  }
});

const dHR = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["Baseline BP"]["SBP"] === "" || aft["Baseline BP"]["PR"] === "" || aft["Minimum BP"]["SBP"] === "" || aft["Minimum BP"]["PR"] === "") {
    return "";
  }
  const baselineSBP = aft["Baseline BP"]["SBP"];
  const baselinePR = aft["Baseline BP"]["PR"];
  const minimumSBP = aft["Minimum BP"]["SBP"];
  const minimumPR = aft["Minimum BP"]["PR"];
  return (minimumPR - baselinePR) / (baselineSBP - minimumSBP);
});

const Neurogenic = atom((get) => {
  const dhr = get(dHR);
  if (dhr === "") {
    return "";
  }
  if (dhr > 0.5) {
    return "Non-neurogenic";
  } else if (dhr === 0.5) {
    return "Borderline";
  } else {
    return "Neurogenic";
  }
});

const DistalArm = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["성별"] === "" || aft["Distal arm"] === "") {
    return "";
  }
  if (aft["성별"] === "남") {
    return aft["Distal arm"] >= 0.3 ? "정상" : aft["Distal arm"] > 0.15 ? "감소" : "많이감소";
  } else {
    return aft["Distal arm"] >= 0.08 ? "정상" : aft["Distal arm"] > 0.04 ? "감소" : "많이감소";
  }
})

const ProximalLeg = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["성별"] === "" || aft["나이"] === "" || aft["Proximal leg"] === "") {
    return "";
  }
  if (aft["성별"] === "남") {
    if (aft["나이"] >= 10 && aft["나이"] <= 29) {
      return aft["Proximal leg"] >= 0.5 ? "정상" : aft["Proximal leg"] > 0.25 ? "감소" : "많이감소";
    } else if (aft["나이"] >= 30 && aft["나이"] <= 49) {
      return aft["Proximal leg"] >= 0.37 ? "정상" : aft["Proximal leg"] > 0.185 ? "감소" : "많이감소";
    } else {
      return aft["Proximal leg"] >= 0.23 ? "정상" : aft["Proximal leg"] > 0.115 ? "감소" : "많이감소";
    }
  } else {
    if (aft["나이"] >= 10 && aft["나이"] <= 29) {
      return aft["Proximal leg"] >= 0.14 ? "정상" : aft["Proximal leg"] > 0.07 ? "감소" : "많이감소";
    } else {
      return aft["Proximal leg"] >= 0.14 ? "정상" : aft["Proximal leg"] > 0.07 ? "감소" : "많이감소";
    }
  }
});

const DistalLeg = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["성별"] === "" || aft["나이"] === "" || aft["Distal leg"] === "") {
    return "";
  }
  if (aft["성별"] === "남") {
    if (aft["나이"] >= 10 && aft["나이"] <= 29) {
      return aft["Distal leg"] >= 0.54 ? "정상" : aft["Distal leg"] > 0.27 ? "감소" : "많이감소";
    } else if (aft["나이"] >= 30 && aft["나이"] <= 49) {
      return aft["Distal leg"] >= 0.38 ? "정상" : aft["Distal leg"] > 0.19 ? "감소" : "많이감소";
    } else {
      return aft["Distal leg"] >= 0.23 ? "정상" : aft["Distal leg"] > 0.115 ? "감소" : "많이감소";
    }
  } else {
    if (aft["나이"] >= 10 && aft["나이"] <= 29) {
      return aft["Distal leg"] >= 0.24 ? "정상" : aft["Distal leg"] > 0.12 ? "감소" : "많이감소";
    } else if (aft["나이"] >= 30 && aft["나이"] <= 49) {
      return aft["Distal leg"] >= 0.15 ? "정상" : aft["Distal leg"] > 0.075 ? "감소" : "많이감소";
    } else {
      return aft["Distal leg"] >= 0.07 ? "정상" : aft["Distal leg"] > 0.035 ? "감소" : "많이감소";
    }
  }
});

const Foot = atom((get) => {
  const aft = get(AFTAtom);
  if (aft["성별"] === "" || aft["나이"] === "" || aft["Foot"] === "") {
    return "";
  }
  if (aft["성별"] === "남") {
    if (aft["나이"] >= 10 && aft["나이"] <= 29) {
      return aft["Foot"] >= 0.34 ? "정상" : aft["Foot"] > 0.17 ? "감소" : "많이감소";
    } else if (aft["나이"] >= 30 && aft["나이"] <= 49) {
      return aft["Foot"] >= 0.31 ? "정상" : aft["Foot"] > 0.155 ? "감소" : "많이감소";
    } else {
      return aft["Foot"] >= 0.27 ? "정상" : aft["Foot"] > 0.135 ? "감소" : "많이감소";
    }
  } else {
    if (aft["나이"] >= 10 && aft["나이"] <= 29) {
      return aft["Foot"] >= 0.09 ? "정상" : aft["Foot"] > 0.045 ? "감소" : "많이감소";
    } else if (aft["나이"] >= 30 && aft["나이"] <= 49) {
      return aft["Foot"] >= 0.07 ? "정상" : aft["Foot"] > 0.035 ? "감소" : "많이감소";
    } else {
      return aft["Foot"] >= 0.05 ? "정상" : aft["Foot"] > 0.025 ? "감소" : "많이감소";
    }
  }
});

const AgeSex = () => {
  const [aft, setAFT] = useAtom(AFTAtom);
  return (
    <table className="table-auto">
      <thead>
      <tr>
        <th className="px-1 py-1 border-2 text-xs tracking-wider">나이</th>
        <td className="border-2">
          <Input
            size="small"
            variant="borderless"
            value={aft["나이"]}
            onChange={(e) => {
              setAFT((prev) => {
                var newAFT = {...prev};
                newAFT["나이"] = e.target.value;
                return newAFT;
              });
            }}
          />
        </td>
      </tr>
      <tr>
        <th className="px-1 py-1 border-2 text-xs font-medium tracking-wider">성별</th>
        <th className="border-2">
          <div className="px-1 flex flex-row justify-start text-xs font-medium">
            <Radio.Group value={aft["성별"]} onChange={e => {
              setAFT((prev) => {
                var newAFT = {...prev};
                newAFT["성별"] = e.target.value;
                return newAFT;
              });
            }}>
              <Radio value={"남"}>남</Radio>
              <Radio value={"여"}>여</Radio>
            </Radio.Group>
          </div>
        </th>
      </tr>
      </thead>
    </table>
  );
};

const LeftTable = () => {
  const [aft, setAFT] = useAtom(AFTAtom);
  return (
    <table className="table-auto">
      <tbody>
      {Object.keys(aft).filter(value => value !== '나이' && value !== '성별').map((item, index) => (
        <tr key={index} className="items-center text-center">
          {item === "Tilt test"
            ? <td className="col-span-4 border-2 px-1 py-1 text-xs font-medium">Tilt test</td>
            : <td className="border-2 px-1 py-1 text-xs font-medium">{item}</td>}

          {["E-I difference", "E-I ratio", "Valsalva ratio", "Distal arm", "Proximal leg", "Distal leg", "Foot"].includes(item) &&
            <td className="border-2">
              <Input
                size="small"
                variant="borderless"
                value={aft[item]}
                onChange={(e) => {
                  setAFT((prev) => {
                    var newAFT = {...prev};
                    newAFT[item] = e.target.value;
                    return newAFT;
                  });
                }}
              />
            </td>
          }

          {item === "Last phase II" &&
            <td className="border-2 pl-1 text-start">
              <Radio.Group value={aft[item]} onChange={(e) => {
                setAFT((prev) => {
                  var newAFT = {...prev};
                  newAFT[item] = e.target.value;
                  return newAFT;
                });
              }}>
                <Radio value={"정상"}>정상</Radio>
                <Radio value={"mild"}>mild</Radio>
                <Radio value={"mod"}>mod</Radio>
                <Radio value={"severe"}>severe</Radio>
              </Radio.Group>
            </td>
          }

          {item === "Phase IV" &&
            <td className="border-2 pl-1 text-start">
              <Radio.Group value={aft[item]} onChange={(e) => {
                setAFT((prev) => {
                  var newAFT = {...prev};
                  newAFT[item] = e.target.value;
                  return newAFT;
                });
              }}>
                <Radio value={"정상"}>정상</Radio>
                <Radio value={"mild"}>mild</Radio>
                <Radio value={"severe"}>severe</Radio>
              </Radio.Group>
            </td>
          }

          {item === "Tilt test" &&
            <td className="border-2 pl-1 text-start">
              <Radio.Group value={aft[item]} onChange={(e) => {
                setAFT((prev) => {
                  var newAFT = {...prev};
                  newAFT[item] = e.target.value;
                  return newAFT;
                });
              }}>
                <div className="flex flex-col">
                  <Radio value={"Normal"}>Normal</Radio>
                  <Radio value={"Orthostatic hypotension"}>Orthostatic hypotension</Radio>
                  <Radio value={"Initial orthostatic hypotension"}>Initial orthostatic hypotension</Radio>
                  <Radio value={"POTS"}>POTS</Radio>
                </div>
              </Radio.Group>
            </td>
          }

          {(item === "Baseline BP" || item === "Minimum BP") &&
            <td className="border-2 pl-1 text-start">
              <div className="flex flex-row text-xs items-center">
                SBP
                <div className="w-2"/>
                <Input
                  size="small"
                  className="w-14"
                  value={aft[item]["SBP"]}
                  onChange={(e) => {
                    setAFT((prev) => {
                      var newAFT = {...prev};
                      newAFT[item]["SBP"] = e.target.value;
                      return newAFT;
                    });
                  }}
                />
                <div className="w-2"/>
                PR
                <div className="w-2"/>
                <Input
                  size="small"
                  className="w-14"
                  value={aft[item]["PR"]}
                  onChange={(e) => {
                    setAFT((prev) => {
                      var newAFT = {...prev};
                      newAFT[item]["PR"] = e.target.value;
                      return newAFT;
                    });
                  }}
                />
                <div className="w-2"/>
              </div>
            </td>
          }
        </tr>
      ))}
      </tbody>
    </table>
  );
};

const RightTable = () => {
  const [aft, setAFT] = useAtom(AFTAtom);
  const eiDifference = useAtomValue(EIDifference);
  const eiRatio = useAtomValue(EIRatio);
  const valsalvaRatio = useAtomValue(ValsalvaRatio);
  const parasympathetic = useAtomValue(Parasympathetic);
  const sympathetic = useAtomValue(Sympathetic);
  const dhr = useAtomValue(dHR);
  const neurogenic = useAtomValue(Neurogenic);
  const distalArm = useAtomValue(DistalArm);
  const proximalLeg = useAtomValue(ProximalLeg);
  const distalLeg = useAtomValue(DistalLeg);
  const foot = useAtomValue(Foot);

  return (
    <table className="table-auto">
      <tbody>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">E-I difference</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{eiDifference}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">E-I ratio</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{eiRatio}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Valsalva ratio</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{valsalvaRatio}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Parasympathetic</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{parasympathetic}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Sympathetic</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{sympathetic}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Tile test</td>
        <td className="border-2 pl-1 text-start">
          <Radio.Group value={aft['Tilt test']} onChange={(e) => {
            setAFT((prev) => {
              var newAFT = {...prev};
              newAFT['Tilt test'] = e.target.value;
              return newAFT;
            });
          }}>
            <div className="flex flex-col">
              <Radio value={"Normal"}>Normal</Radio>
              <Radio value={"Orthostatic hypotension"}>Orthostatic hypotension</Radio>
              <Radio value={"Initial orthostatic hypotension"}>Initial orthostatic hypotension</Radio>
              <Radio value={"POTS"}>POTS</Radio>
            </div>
          </Radio.Group>
        </td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">dHR/dSBP</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{dhr}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Neurogenic</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{neurogenic}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Distal arm</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{distalArm}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Proximal Leg</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{proximalLeg}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Distal Leg</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{distalLeg}</td>
      </tr>
      <tr>
        <td className="border-2 px-1 py-1 text-xs font-medium text-center">Foot</td>
        <td className="border-2 px-1 py-1 font-medium text-xs">{foot}</td>
      </tr>
      </tbody>
    </table>
  );
};

export const AFTTab = () => {
  return (
    <div className="flex-1 px-1 py-1 flex flex-col">
      <div>
        <AgeSex/>
      </div>
      <div className="h-4"/>
      <div className="flex flex-row">
        <LeftTable/>
        <div className="w-4"/>
        <RightTable/>
      </div>
    </div>
  );
};
