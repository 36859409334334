import { Checkbox, Dropdown, Input } from "antd";
import Title from "antd/es/typography/Title";
import { atom, useAtom } from "jotai";
import {atomWithDefault} from "jotai/utils";

export const emgLowerTabResult = atom((get) => {
  const emg = get(emgLowerAtom);
  const etcName = get(etcMuscle);
  return result(emg, etcName);
});

function result(emg, etcName) {
  var elem = [];

  for (const muscleCode of Object.keys(emg["Right"])) {
    for (const side of ["Right", "Left"]) {
      var muscleName = muscleMap.find((m) => m.name === muscleCode).value;
      if (muscleCode === "etc") {
        muscleName = etcName;
      }
      if (emg[side][muscleCode].normal) {
        elem.push(`- Normal EMG in ${side.toLowerCase()} ${muscleName} muscle.`);
      } else {
        const subElem1 = [];
        const subElem2 = [];
        const subElem3 = [];
        var count = 0;
        for (const column of Object.keys(dropdownColumns)) {
          if (emg[side][muscleCode][column] !== "") {
            if (count < 6) {
              subElem1.push(dropdownColumns[column][emg[side][muscleCode][column]].toLowerCase());
            } else if (count < 9) {
              subElem2.push(dropdownColumns[column][emg[side][muscleCode][column]].toLowerCase());
            } else if (count < 10) {
              subElem3.push(dropdownColumns[column][emg[side][muscleCode][column]].toLowerCase());
            }
          }
          count = count + 1;
        }
        var res1 = "";
        if (subElem1.length === 1) {
          res1 = subElem1[0];
        } else if (subElem1.length > 1) {
          res1 = subElem1.splice(0, subElem1.length - 1).join(", ") + " and " + subElem1[subElem1.length - 1];
        }
        var res2 = "";
        if (subElem2.length > 0) {
          res2 = subElem2.join(", ") + " MUAPs";
        }
        var res3 = "";
        if (subElem3.length > 0) {
          res3 = subElem3[0];
        }
        var final = "";
        if (res1 !== "" && res2 !== "") {
          final += res1 + " and " + res2;
        } else if (res1 !== "") {
          final += res1;
        } else if (res2 !== "") {
          final += res2;
        }
        if (final !== "" && res3 !== "") {
          final += " with " + res3;
        } else if (res3 !== "") {
          final += res3;
        }

        if (final !== "") {
          final = final.charAt(0).toUpperCase() + final.slice(1);
          elem.push(`- ${final} in ${side.toLowerCase()} ${muscleName} muscle.`);
        }
      }
    }
  }

  if (elem.filter((v) => v != null).length === 0) {
    return "";
  }

  return elem.filter((v) => v != null).join("\n");
}

const muscleMap = [
  { name: "TA", value: "tibialis anterior" },
  { name: "PL", value: "peroneus longus" },
  { name: "EDB", value: "extensor digitorum brevis" },
  { name: "EHL", value: "extensor hallucis longus" },
  { name: "GCN", value: "median gastrocnemius" },
  { name: "TP", value: "tibialis posterior" },
  { name: "AH", value: "abductor hallucis brevis" },
  { name: "BFshort", value: "biceps femoris short head" },
  { name: "VL", value: "vastus lateralis" },
  { name: "VM", value: "vastus medialis" },
  { name: "RF", value: "rectus femoris" },
  { name: "Iliacus", value: "iliacus" },
  { name: "TFL", value: "tensor fascia lata" },
  { name: "GluMax", value: "gluteus maximus" },
  { name: "GluMed", value: "glutens medius" },
  { name: "T10", value: "T10 paraspinal" },
  { name: "T12", value: "T12 paraspinal" },
  { name: "L1", value: "L1 paraspinal" },
  { name: "L2", value: "L2 paraspinal" },
  { name: "L3", value: "L3 paraspinal" },
  { name: "L4", value: "L4 paraspinal" },
  { name: "L5", value: "L5 paraspinal" },
  { name: "S1", value: "S1 paraspinal" },
  { name: "etc", value: "" },
];

export const etcMuscle = atomWithDefault(()=>(""));

const dropdownColumns = {
  Insertion: {
    Inc: "Increased insertion activities",
    Dec: "Decreased insertion activities",
  },
  Fib: {
    "1+": "Grade 1+ fibrillation potentials",
    "2+": "Grade 2+ fibrillation potentials",
    "3+": "Grade 3+ fibrillation potentials",
    "4+": "Grade 4+ fibrillation potentials",
  },
  PSW: {
    "1+": "Grade 1+ positive sharp waves",
    "2+": "Grade 2+ positive sharp waves",
    "3+": "Grade 3+ positive sharp waves",
    "4+": "Grade 4+ positive sharp waves",
  },
  Fasciculation: {
    "+": "Fasciculation potentials",
  },
  Myotonic: {
    "+": "Myotonic discharges",
  },
  CRD: {
    "+": "Complex repetitive discharges",
  },
  Amp: {
    large: "Large amplitude",
    small: "Small amplitude",
  },
  Duration: {
    Long: "Long duration",
    Short: "Short duration",
  },
  Poly: {
    "+": "Polyphasic",
  },
  Recruitment: {
    Early: "Early recruitment",
    Reduced: "Reduced recruitment",
  },
};

const rowDefault = {
  normal: false,
  Insertion: "",
  Fib: "",
  PSW: "",
  Fasciculation: "",
  Myotonic: "",
  CRD: "",
  Amp: "",
  Duration: "",
  Poly: "",
  Recruitment: "",
};

export const emgLowerAtom = atomWithDefault(()=>({
  Right: {
    TA: { ...rowDefault },
    PL: { ...rowDefault },
    EDB: { ...rowDefault },
    EHL: { ...rowDefault },
    GCN: { ...rowDefault },
    TP: { ...rowDefault },
    AH: { ...rowDefault },
    BFshort: { ...rowDefault },
    VL: { ...rowDefault },
    VM: { ...rowDefault },
    RF: { ...rowDefault },
    Iliacus: { ...rowDefault },
    TFL: { ...rowDefault },
    GluMax: { ...rowDefault },
    GluMed: { ...rowDefault },
    T10: { ...rowDefault },
    T12: { ...rowDefault },
    L1: { ...rowDefault },
    L2: { ...rowDefault },
    L3: { ...rowDefault },
    L4: { ...rowDefault },
    L5: { ...rowDefault },
    S1: { ...rowDefault },
    etc: { ...rowDefault },
  },
  Left: {
    TA: { ...rowDefault },
    PL: { ...rowDefault },
    EDB: { ...rowDefault },
    EHL: { ...rowDefault },
    GCN: { ...rowDefault },
    TP: { ...rowDefault },
    AH: { ...rowDefault },
    BFshort: { ...rowDefault },
    VL: { ...rowDefault },
    VM: { ...rowDefault },
    RF: { ...rowDefault },
    Iliacus: { ...rowDefault },
    TFL: { ...rowDefault },
    GluMax: { ...rowDefault },
    GluMed: { ...rowDefault },
    T10: { ...rowDefault },
    T12: { ...rowDefault },
    L1: { ...rowDefault },
    L2: { ...rowDefault },
    L3: { ...rowDefault },
    L4: { ...rowDefault },
    L5: { ...rowDefault },
    S1: { ...rowDefault },
    etc: { ...rowDefault },
  },
}));

const InputEtcMuscle = () => {
  const [etc, setEtc] = useAtom(etcMuscle);
  return (
    <Input
      size="small"
      variant="borderless"
      placeholder="직접 입력"
      value={etc}
      className="px-0 py-0"
      onChange={(e) => {
        setEtc(e.target.value);
      }}
    />
  );
};

const EMGTable = ({ side }) => {
  const [emg, setEMG] = useAtom(emgLowerAtom);
  return (
    <table className="divide-y divide-gray-200 table-fixed">
      <thead className="bg-slate-400">
        <tr>
          <th className="border-2 text-xs font-medium tracking-wider w-8 break-all">정상</th>
          <th className="border-2 text-xs font-medium tracking-wider">Muscle</th>
          {Object.keys(dropdownColumns).map((column, index) => (
            <th
              key={index}
              className={
                column === "Recruitment"
                  ? "border-2 text-xs font-medium tracking-wider w-14 break-all"
                  : "border-2 text-xs font-medium tracking-wider w-8 break-all"
              }
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {muscleMap.map((row, index) => (
          <tr key={index} className="items-center text-center">
            <td className="border-2">
              <Checkbox
                checked={emg[side][row.name].normal}
                onChange={(e) => {
                  setEMG((prev) => {
                    var newEMG = { ...prev };
                    newEMG[side][row.name] = { ...rowDefault, normal: e.target.checked };
                    return newEMG;
                  });
                }}
              />
            </td>
            <td className="text-left border-2 text-xs font-medium text-gray-900 w-20">
              {row.name === "etc" ? <InputEtcMuscle /> : row.name}
            </td>
            {Object.keys(dropdownColumns).map((column, index) =>
              emg[side][row.name].normal ? (
                <td key={index} className="text-center px-0 py-0 border-2 text-xs font-medium text-gray-900 bg-gray-100"></td>
              ) : (
                <td key={index} className="text-center px-0 py-0 border-2 text-xs font-medium text-gray-900">
                  <Dropdown
                    trigger={["click"]}
                    size="small"
                    mouseEnterDelay={0.07}
                    mouseLeaveDelay={0.07}
                    disabled={emg[side][row.name].normal}
                    menu={{
                      items: Object.entries(dropdownColumns[column]).map(([key]) => ({
                        key: key,
                        label: key,
                      })),
                      onClick: ({ key }) => {
                        setEMG((prev) => {
                          var newEMG = { ...prev };
                          newEMG[side][row.name][column] = key;
                          return newEMG;
                        });
                      },
                    }}
                  >
                    <div
                      className={
                        column === "Recruitment"
                          ? "h-5 w-14 items-center text-center break-all"
                          : "h-5 w-8 items-center text-center break-all"
                      }
                    >
                      {emg[side][row.name][column]}
                    </div>
                  </Dropdown>
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const EMGLowerTab = () => {
  return (
    <div className="px-1 py-1 flex-1">
      <div className="flex flex-col w-fit">
        <div className="flex flex-row w-fit">
          <div className="flex flex-col">
            <Title level={5}>Right</Title>
            <EMGTable side="Right" />
          </div>
          <div className="w-4" />
          <div className="flex flex-col">
            <Title level={5}>Left</Title>
            <EMGTable side="Left" />
          </div>
        </div>
      </div>
    </div>
  );
};
